import context from '@truckdown/systems';
import { IInitializer, IUserContext } from "@truckdown/systems";
import { defineAsyncComponent } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueSmoothScroll from 'vue3-smooth-scroll';
import modal from "./Modal/Modal.vue";
import lazy from "./Lazy/Lazy.vue";
import icon from "./Icon/Icon.vue";
import captcha from "./Captcha/Captcha.vue";
import headerMenu from "./HeaderMenu/HeaderMenu.vue";
import adminTasksLink from "./AdminTasksLink/AdminTasksLink.vue";
import adminSearch from "./AdminSearch/AdminSearch.vue";
import sideNav from "./SideNav/SideNav.vue";
import genericPager from "./GenericPager/GenericPager.vue";

import { IAppUserContext } from '../IAppUserContext';

class ComponentInitializer implements IInitializer {
    name: string = 'ComponentInitializer';
    order: number = 0;

    initialize(context: IUserContext): Promise<void> {
        let app = (context as IAppUserContext).app;
        app.use(VueSmoothScroll, {
            duration: 400,
            updateHistory: true
        });

        app.component('v-lazy-image',
            defineAsyncComponent(() => import(/* webpackChunkName: "lazy-image" */ 'v-lazy-image')));
        app.component('VueDatePicker',
            defineAsyncComponent(() => import(/* webpackChunkName: "datepicker" */ '@vuepic/vue-datepicker'))); //8099
        app.component('fa-icon', FontAwesomeIcon);
        app.component('modal', modal);
        app.component('lazy', lazy);
        app.component('icon', icon);
        app.component('captcha', captcha);
        app.component('header-menu', headerMenu);
        app.component('admin-tasks-link', adminTasksLink);
        app.component('admin-search', adminSearch);
        app.component('side-nav', sideNav);
        app.component('generic-pager', genericPager);
        app.component('context-info',
            defineAsyncComponent(() => import(/* webpackChunkName: "context-info" */ './ContextInfo/ContextInfo.vue')));
        app.component('control-panel',
            defineAsyncComponent(() => import(/* webpackChunkName: "control-panel" */ './ControlPanel/ControlPanel.vue')));
        app.component('create-task',
            defineAsyncComponent(() => import(/* webpackChunkName: "create-task" */ './CreateTask/CreateTask.vue'))); //2488
        app.component('create-task-modal',
            defineAsyncComponent(() => import(/* webpackChunkName: "create-task-modal" */ './CreateTaskModal/CreateTaskModal.vue')));

        return Promise.resolve();
    }
}

context.registerService({
    name: 'IInitializer',
    activator: async () => {
        return new ComponentInitializer();
    }
});
