<template>
    <div>
        <div class="pager-pagination">
            <div v-if="!loading && total > 0" class="result-counts td-text-subdued td-text-italic td-text-mice">
                Displaying {{ displayStart }} - {{ displayEnd }} of {{ total }}
            </div>
            <div v-if="loading && total > 0" class="result-counts td-text-subdued td-text-italic td-text-mice">Displaying <icon name="faSpinner" type="solid" spin></icon></div>
            <div v-if="!loading && total == 0" class="result-counts td-text-subdued td-text-italic td-text-mice">
                No records to display
            </div>
            <div class="td-pagination-btns my-1 slim">
                <button :disabled="displayStart == 1 || total == 0" :class="['secondary, previous', {'disabled': displayStart == 1 || total == 0}]"
                        @click.prevent="onPrev"
                        title="Back">
                    <icon type="solid" name="faAngleLeft" aria-hidden="true"></icon>
                </button>
                <button :class="['next', 'secondary', {'disabled': displayEnd == total}]"
                        @click.prevent="onNext"
                        title="Next"
                        :disabled="displayEnd == total && total > 0">
                    <icon type="solid" name="faAngleRight" aria-hidden="true"></icon>
                </button>
            </div>
        </div>
        <slot></slot>
        <div class="pager-pagination down" v-if="total > 0">
            <div class="result-counts td-text-subdued td-text-italic td-text-mice">
                Displaying {{ displayStart }} - {{ displayEnd }} of {{ total }}
            </div>
            <div class="td-pagination-btns my-1 slim">
                <button :disabled="displayStart == 1" :class="['secondary, previous', {'disabled': displayStart == 1}]"
                        @click.prevent="onPrev"
                        title="Back">
                    <icon type="solid" name="faAngleLeft" aria-hidden="true"></icon>
                </button>
                <button :class="['next', 'secondary', {'disabled': displayEnd == total}]"
                        @click.prevent="onNext"
                        title="Next"
                        :disabled="displayEnd == total">
                    <icon type="solid" name="faAngleRight" aria-hidden="true"></icon>
                </button>
            </div>
        </div>
    </div>
</template>


<script>
    import script from './GenericPager';
    export default script;</script>

<style lang="less">
    @import "./GenericPager.less";
</style>


