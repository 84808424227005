import './Setup';
import './Components';

import { default as context } from '@truckdown/systems';
import { IAppUserContext } from './IAppUserContext';

export type { IAppUserContext };
export type { IIconLoader } from './IIconLoader';
export type { INavItem } from './INavItem';

context.initialize().then(() => {
    (window as any).isSysInitialized = true;
    console.log('System initialized');
});

export default context as IAppUserContext;
