import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { definition as faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { definition as faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { IIconLoader } from '../IIconLoader';

interface IIconStore {
    solid: { prefix: string, icon: string; }[];
    branded: { prefix: string, icon: string; }[];
    regular: { prefix: string, icon: string; }[];
}

const store: IIconStore = {
    solid: [],
    branded: [],
    regular: []
}

export class IconLoader implements IIconLoader {
    constructor() {
        this.addIcon(faSpinner);
        this.addIcon(faBars);
    }

    private async getIcon(mod: string, attempts?: number): Promise<IconDefinition | undefined> {
        let loader = this;
        if (!window.exports) {
            window.exports = {};
        }
        try {
            await import(/* webpackIgnore: true */'data:text/javascript;base64,' + btoa(mod));

            let definition = (window.exports as any)?.definition;
            if (!definition) {
                console.log('Invalid Icon definition.', window.exports);
                throw new Error('Invalid Icon definition.')
            } else {
                return definition as IconDefinition;
            }
        } catch (err: any) {
            if (err?.message == 'Object.defineProperty called on non-object') {
                if (attempts && attempts > 2) {
                    throw err;
                }
                let promise = new Promise(resolve => setTimeout(resolve, 100));
                await promise;
                let att = 1;
                if (attempts) {
                    att = attempts + 1;
                }
                return await loader.getIcon(mod, att);
            }
            throw err;
        }
    }

    private async getDefinition(url: string): Promise<IconDefinition | undefined> {
        let loader = this;
        const response = await fetch(url);
        if (response.ok) {
            let js = await response.text();

            let require = js.match(/require\('\.(\S*)\)/);
            if (require != null && require.length >= 1) {
                let name = require[0].substring(10);
                name = name.substring(0, name.length - 2);

                let newUrl = url.substring(0, url.lastIndexOf('/') + 1) + name + '.js';
                return await loader.getDefinition(newUrl);
            }

            var icon = await loader.getIcon(js);
            return icon;
        }
    }

    private async fetchIcon(prefix: string, icon: string, url: string) {
        let loader = this;
        let definition = await loader.getDefinition(url);
        if (definition) {
            loader.addLoadedIcon(prefix, icon, definition);
        }
    }

    private async loadBrandIcon(prefix: string, icon: string, name: string) {
        let url = `/systems/js/icons/brand/fa${name}.js`;
        await this.fetchIcon(prefix, icon, url);
    }

    private async loadRegularIcon(prefix: string, icon: string, name: string) {
        let url = `/systems/js/icons/regular/fa${name}.js`;
        await this.fetchIcon(prefix, icon, url);
    }

    private async loadSolidIcon(prefix: string, icon: string, name: string) {
        let url = `/systems/js/icons/solid/fa${name}.js`;
        await this.fetchIcon(prefix, icon, url);
    }

    private loading: { prefix: string, icon: string; }[] = [];

    private isLoading(prefix: string, icon: string) {
        return this.loading.some((i) => i.prefix == prefix && i.icon == icon);
    }

    private startLoading(prefix: string, icon: string) {
        this.loading.push({
            prefix: prefix,
            icon: icon
        });
    }

    private stopLoading(prefix: string, icon: string) {
        this.loading = this.loading.filter((i) => i.prefix != prefix || i.icon != icon);
    }

    private async wait(prefix: string, icon: string) {
        var loader = this;
        while (loader.isLoading(prefix, icon)) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
    }

    async loadIcon(prefix: string, icon: string) {
        let loader = this;
        if (loader.isLoading(prefix, icon)) {
            await loader.wait(prefix, icon);
        }
        loader.startLoading(prefix, icon);

        try {
            if (!loader.hasIcon(prefix, icon)) {
                if (prefix === 'fab') {
                    await loader.loadBrandIcon(prefix, icon, loader._convertIcon(icon));
                } else if (prefix === 'far') {
                    await loader.loadRegularIcon(prefix, icon, loader._convertIcon(icon));
                } else if (prefix === 'fas') {
                    await loader.loadSolidIcon(prefix, icon, loader._convertIcon(icon));
                }
            }
        }
        catch (err) {
            console.log(err);
        }
        finally {
            loader.stopLoading(prefix, icon);
        }
    }

    private _convertIcon(icon: string): string {
        return icon.replace(/\-/g, ' ')
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return word.toUpperCase();
            }).replace(/\s+/g, '');
    }


    private _hasIcon(icons: { prefix: string, icon: string; }[], icon: string) {
        return icons.some((i) => {
            return i.icon == icon;
        });
    }

    hasIcon(prefix: string, icon: string) {
        if (prefix === 'fab') {
            return this._hasIcon(store.branded, icon);
        } else if (prefix === 'far') {
            return this._hasIcon(store.regular, icon);
        } else if (prefix === 'fas') {
            return this._hasIcon(store.solid, icon);
        }
        return false;
    }

    private addLoadedIcon(prefix: string, icon: string, definition: IconDefinition) {
        if (definition) {
            if (!this.hasIcon(definition.prefix, definition.iconName)) {
                this.addIcon(definition);
            }
            if (prefix != definition.prefix || icon != definition.iconName) {
                if (definition.prefix == 'fas') {
                    store.solid.push({
                        prefix: prefix,
                        icon: icon
                    });
                }
                else if (definition.prefix == 'far') {
                    store.regular.push({
                        prefix: prefix,
                        icon: icon
                    });
                }
                else if (definition.prefix == 'fab') {
                    store.regular.push({
                        prefix: prefix,
                        icon: icon
                    });
                }
            }
        }
    }

    addIcon(definition: IconDefinition) {
        if (definition.prefix == 'fas') {
            store.solid.push({
                prefix: definition.prefix,
                icon: definition.iconName
            });
        }
        else if (definition.prefix == 'far') {
            store.regular.push({
                prefix: definition.prefix,
                icon: definition.iconName
            });
        }
        else if (definition.prefix == 'fab') {
            store.regular.push({
                prefix: definition.prefix,
                icon: definition.iconName
            });
        }
        library.add(definition);
    }

    getStore() {
        return store;
    }
}