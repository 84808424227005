import context from '@truckdown/systems';
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { IIconLoader } from '../../IIconLoader';
import './IconStringExtensions';

interface IProps {
    type: string;
    name: string;
}

interface IData {
    isLoading: Ref<boolean>;
}

const getData = function (props: IProps) {
    return {
        isLoading: ref<boolean>(true)
    } as IData;
}

const getPrefix = function (type: string): string {
    if (type == 'regular') {
        return 'far';
    }
    if (type == 'brand') {
        return 'fab';
    }
    return 'fas';
}

const getIcon = function (name: string): string {
    let icon = name;
    if (name.startsWith('fa')) {
        icon = name.substring(2);
    }

    icon = icon
        .replace(/[A-Z]+(?![a-z])|[A-Z]/g,
            ($, ofs) => (ofs ? "-" : "") + $.toLowerCase());

    return icon;
}

const getComputed = function (props: IProps) {
    return {
        prefix: computed(() => {
            return getPrefix(props.type);
        }),
        icon: computed(() => {
            return getIcon(props.name);
        }),
        iconProp: computed(() => {
            
            return [getPrefix(props.type), getIcon(props.name)];
        })
    }
}

export default defineComponent({
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        spin: Boolean
    },
    setup: function (props, ctx) {
        const data = getData(props);
        const computed = getComputed(props);

        onMounted(async () => {
            let loader = await context.getService<IIconLoader>('IIconLoader');
            await loader.loadIcon(getPrefix(props.type), getIcon(props.name));
            data.isLoading.value = false;
        })

        return {
            ...data,
            ...computed
        }
    }
});
