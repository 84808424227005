<template>
    <span>
        <template v-if="isLoading">
            <svg class="svg-inline--fa fa-spinner fa-spin" v-bind="$attrs" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" fill="currentColor" d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"></path></svg>
        </template>
        <template v-if="!isLoading">
            <fa-icon :icon="iconProp" :spin="spin" v-bind="$attrs"></fa-icon>
        </template>
    </span>
</template>

<script>import script from './Icon';
    export default script;</script>
