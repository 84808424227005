import { default as context } from '@truckdown/systems';

import { VueCreatorInitializer } from './VueCreatorInitializer';
import { VueMountInitializer } from './VueMountInitializer';
import { IconLoader } from './IconLoader';
import { IAppUserContext } from '../IAppUserContext';

let appContext = context as IAppUserContext;
appContext.analytics = (data: any) => {
    if ((window as any).dataLayer) {
        (window as any).dataLayer.push(data)
    }
};

const vueCreator = new VueCreatorInitializer();
const vueMounter = new VueMountInitializer();
const iconLoader = new IconLoader();

context.registerService({
    name: 'IIconLoader',
    activator: async () => {
        return iconLoader;
    }
});
context.registerService({
    name: 'IInitializer',
    activator: async () => {
        return vueCreator;
    }
});
context.registerService({
    name: 'IInitializer',
    activator: async () => {
        return vueMounter;
    }
});