import { defineComponent, ref, Ref, computed, watch } from 'vue';

interface IPagerData {
    lastObjects: Ref<any[]>,
    currentObject: Ref<any>,
}

const getInitialData = function (props: any): IPagerData {
    let initialValue: any[] = [];
    if (props.lastObject) {
        initialValue = [props.lastObject];
    }

    return {
        lastObjects: ref<any[]>(initialValue),
        currentObject: ref<any>(initialValue[initialValue.length - 1]),
    }
}

const getComputed = function (props: any, data: IPagerData) {
    return {
        displayStart: computed(() => {
            if (data.lastObjects.value.length > 1) {
                return (data.lastObjects.value.length - 1) * props.pageCount + 1;
            } else if (data.lastObjects.value.length == 1) {
                return 1;
            } else {
                return 0;
            }
        }),
        displayEnd: computed(() => {
            if (data.lastObjects.value.length > 0) {
                const end = data.lastObjects.value.length * props.pageCount;
                return end > props.count ? props.count : end;
            } else {
                return 0;
            }

        }),
        total: computed(() => {
            return props.count;
        })
    }
}

const getMethods = function (props: any, data: IPagerData, ctx: any) {
    return {
        onPrev: function () {
            const currentIndex = data.lastObjects.value.findIndex(obj => obj === data.currentObject.value);
            if (currentIndex > 0) {
                data.currentObject.value = data.lastObjects.value[currentIndex - 1];
                data.lastObjects.value = data.lastObjects.value.slice(0, currentIndex - 1);
                ctx.emit('update-list', data.lastObjects.value[data.lastObjects.value.length - 1]);
            }
        },
        onNext: function () {
            if (props.count > props.currentCount) {
                const currentIndex = data.lastObjects.value.findIndex(obj => obj === data.currentObject.value);
                if (currentIndex < data.lastObjects.value.length - 1) {
                    data.currentObject.value = data.lastObjects.value[currentIndex + 1];
                } else {
                    ctx.emit('update-list', data.currentObject.value);
                    data.currentObject.value = data.lastObjects.value[data.lastObjects.value.length - 1]
                }
            }
        }
    };
}

export default defineComponent({
    name: 'generic-pager',
    props: {
        count: Number,
        pageCount: Number,
        currentCount: Number,
        lastObject: Object,
        reset: Boolean,
        loading: Boolean
    },
    emits: ['update-list'],
    setup: function (props, ctx) {
        let data = getInitialData(props);
        let computed = getComputed(props, data);
        let methods = getMethods(props, data, ctx);

        watch(
            () => props.lastObject,
            (newVal, oldVal) => {
                if (newVal) {
                    data.lastObjects.value.push(newVal);
                    data.currentObject.value = newVal;
                }
            },
            { deep: true }
        );

        watch(() => props.reset, function (newItem, oldItem) {
            if (newItem) {
                if (props.lastObject) {
                    data.lastObjects.value = [props.lastObject];
                    data.currentObject.value = data.lastObjects.value[data.lastObjects.value.length - 1]
                } else {
                    data.lastObjects.value = [];
                    data.currentObject.value = {
                        lastId: null,
                        lastDate: null,
                        lastPaymentTime: null,
                        lastDueTime: null,
                    };
                }
            }
        });

        return {
            ...data,
            ...computed,
            ...methods
        };
    }
});